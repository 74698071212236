.sidebar {
    flex: 1;
    height: 100vh;
    background-color: #10416D;
    position: sticky;
    top: 0px;
    max-width: 70px;
}

.sidebarWrapper {
    padding: 5px;
    color: white;
}

/* .sidebarWrapper .logoadmin img {
    max-width: 200px;
    color: #ffffff;
} */

.sidebarMenu {
    margin-bottom: 10px;
}

.sidebarlist {
    list-style: none;
    padding: 5px;
}

.sidebarlistItem {
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 15px;
}

.sidebarlistItem.active,
.sidebarlistItem:hover {
    background-color: white;
    color: #10416D;

}

/* .sidebarIcon {
    margin-right: 5px;
    font-size: 30px !important;
} */

/* .logoadmin {
    display: flex;
    margin-bottom: 20px;
} */

.p-lr-5 {
    padding: 0px 5px;
}