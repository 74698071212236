a {
    color: #10416D;
}


/* .container {
    display: flex;
} */

.link {
    text-decoration: none;
    color: inherit;
}

.pageHeaderIcon {
    color: #10416D;
    margin-right: 5px;
    font-size: 30px !important;

}

.pageHeader {
    display: flex;
    justify-content: space-evenly;
    margin: 0px 0px 5px 0px;
    border-bottom: 1px solid #10416D;
}


.pageHeaderText {
    display: flex;
    flex-direction: column;
}

.pageHeaderTitle {
    font-weight: 300;
    color: #10416D;
    font-size: 30px;
    text-transform: uppercase;
    align-items: center;
    justify-content: right;
    display: flex;
}

.pageHeaderDescription {
    font-weight: 300;
    color: #757575;
}

.pageTableTitle {
    font-weight: 600;
    color: #003a41;
    font-size: 20px;
}


.pageTableTitleNoButton {
    font-weight: 600;
    color: #003a41;
    font-size: 20px;
    margin-bottom: 15px;
}

.MuiDataGrid-root {
    border: 1px solid #e6e7eb !important;
    border-radius: 20px !important;
    height: auto !important;
}

.MuiDataGrid-columnHeaderWrapper {
    background-color: #f9fafc;
    border-radius: 20px 20px 0px 0px;
}

.userListActions a {
    text-decoration: none !important;
}

.addButtonContainer a {
    text-decoration: none !important;
}

.addButtonText {
    width: 95px;
    justify-content: center;
    text-decoration: none !important;
}

.addElementButton {
    background-color: #10416D;
    padding: 8px 5px 8px 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    width: 150px;
    text-decoration: none !important;
}

.addElementIcon {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.6);
    color: white;
    font-size: 18px !important;
    padding: 5px;
}

.pageSubHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}

.actionButtonText {
    width: 50px;
    justify-content: center;
    text-decoration: none;
}

.actionElementButton {
    background-color: #10416D;
    padding: 10px 25px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    margin-right: 10px;
    text-decoration: none !important;
}

.actionElementButton:disabled {
    opacity: 0.7;
    cursor: default;
}

/* .buttonContainer .MuiButton-contained.Mui-disabled {
    color: #ffffff !important;
    width: 100px !important;
}

.buttonContainer .MuiButton-contained.Mui-disabled span {
    padding: 0px !important;
} */

.buttonContainer .MuiButton-containedSecondary {
    border-radius: 25px !important;
    width: 100px;
}

.buttonContainer .MuiButton-containedPrimary {
    height: 36px !important;
}

.actionElementButton:hover {
    opacity: 0.7;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0px 6px 0px 0px !important;
}

.actionElementIcon {
    color: white;
    font-size: 20px !important;
    text-decoration: none;
}

.templateButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.templateButtonText {
    justify-content: center;
    text-decoration: none;
}

.templateElementButton {
    background-color: #438882;
    padding: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    text-decoration: none !important;
}

.templateElementIcon {
    color: white;
    font-size: 20px !important;
    text-decoration: none;
    margin-right: 10px;
}

.popupTitle {
    color: #438882;
    font-weight: 600;
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.popupDropdown {
    width: 100%;
    height: 50px;
    padding: 5px 5px 5px 10px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #f9fafb;
    font-size: 16px;
}

.popupText {
    width: 100%;
    height: 30px;
    padding: 5px 0px 5px 15px;
    border: 1px solid #cfd2db;
    border-radius: 5px;
    background-color: #f9fafb;
    font-size: 16px;
    max-width: calc(100% - 15px) !important;
}

/* .MuiFormControl-root {
    width: 100% !important;
    max-width: 300px;
    border-radius: 4px;
    margin-top: 10px !important;
} */

/* .react-datetime-picker__inputGroup__input:invalid {
    background: transparent !important;
} */

/* .react-datetime-picker__wrapper {
    background: transparent !important;
    height: 56px;
    padding: 18.5px 14px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
} */

/* .datefields label {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.54);
} */

/* .react-datetime-picker__button {
    color: #10416D;
    background: transparent !important;
    padding: 0px 0px 0px 4px !important;
    opacity: 0.5;
} */

/* .datefields .MuiSvgIcon-root.info {
    fill: #10416D;
    font-size: 22px;
    margin: 5px;
} */

.color-red {
    color: red;
}

/* .Flip .MuiDataGrid-window {
    transform: rotateX(180deg);
    top: auto !important;
} */

/* .Flip .MuiDataGrid-dataContainer {
    transform: rotateX(180deg);
} */

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    /* background-color: transparent; */
}

::-webkit-scrollbar-track-piece {
    border-radius: 10px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(200, 200, 200, 0.4);
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: rgba(200, 200, 200, 0.4);
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
    /* background-color: rgb(64, 64, 64);
    display: block;
    background-size: 10px;
    background-repeat: no-repeat; */
    background-color: white;
    display: block;
    border-style: solid;
    height: 12px;
    width: 12px;
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
    /* height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>"); */
    border-width: 6px 6px 6px 0;
    border-color: transparent black transparent transparent;

}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    /* background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>"); */
    border-color: transparent black transparent transparent;
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
    /* height: 12px;
    width: 12px;
    background-position: 3px 3px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>"); */
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent black;
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    /* background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>"); */
    border-color: transparent transparent transparent black;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent black transparent;
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    border-color: transparent transparent black transparent;
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 6px 6px 0 6px;
    border-color: black transparent transparent transparent;
}

::-webkit-scrollbar-button:vertical:single-button:increment:hover {
    border-color: black transparent transparent transparent;
}